import React, { useState } from 'react';
import './career.css'; // Ensure this import path is correct

const GoogleForm = () => {

  return (
    <div className="form1-container">
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLScoei0EadeypUu6FISy3LSEw_Bzx9Xbk0-SaiGCQ4kggbzHpQ/viewform?embedded=true"
      width="640"
      height="974"
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      title="Contact Form"
    >
      Loading…
    </iframe>
  </div>
  );
}

export default GoogleForm;
