import React from 'react'

import digital from '../../Assets/Digital marketing.png';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Digitalmarketing = () => {
  return (
    <div className="page-content">
    <div className='container'>
      <h1 className='page-heading digital' >Digital Marketing</h1>
      <img src={digital} alt="" className="middle-image"  />
      <p className='text'>Amplify your brand's reach and impact with strategic Digital Marketing. Digital marketing refers to the use of digital channels, platforms, and technologies to promote products, services, brands, or ideas to target audiences. It encompasses a wide range of strategies and tactics designed to reach consumers where they spend much of their time—online.</p>
       <p className='text2'><br/>A semi-fictional representation of an ideal customer based on market research and real data. Personas help businesses tailor their digital marketing strategies to specific audiences.

      </p>
    </div>
    <div>
    <h2 className='chooseus-heading'>
        Why Choose Us</h2>
    </div>
    <div className="card2-container">
        <div className="card2">
          <img src={costImage} alt="Card 1" />
          <h3>Cost Effective</h3>
        </div>
        <div className="card2">
          <img src={easyImage} alt="Card 2" />
          <h3>Easy of use</h3>
        </div>
        <div className="card2">
          <img src={teamImage} alt="Card 3" />
          <h3>Team Work</h3>
        </div>
        <div className="card2">
          <img src={securityImage} alt="Card 4" />
          <h3>Security</h3>
        </div>
    </div>
    </div>
  )
}

export default Digitalmarketing
