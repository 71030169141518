import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Assets/Logo.png';
import Icon from '../../Assets/icon.png';
import Location from '../../Assets/Location.png';
import Phone from '../../Assets/phn.png';
import Mail from '../../Assets/mail.png';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };
  
  return (
    <div className='footer' id='footer'>
      <div className='footer-content'>
        <div className='footer-content-left'>
          <img src={Logo} alt="Logo" className="Flogo-img" />
          <table>
      <tbody>
        <tr>
          <td>N</td>
          <td>:</td>
          <td>Next-generation</td>
        </tr>
        <tr>
          <td>E</td>
          <td>:</td>
          <td>Evolutionary</td>
        </tr>
        <tr>
          <td>X</td>
          <td>:</td>
          <td>Expertise</td>
        </tr>
        <tr>
          <td>O</td>
          <td>:</td>
          <td>Optimization</td>
        </tr>
        <tr>
          <td>N</td>
          <td>:</td>
          <td>Networked</td>
        </tr>
        <tr>
          <td>I</td>
          <td>:</td>
          <td>Innovation</td>
        </tr>
        <tr>
          <td>C</td>
          <td>:</td>
          <td>Connectivity</td>
        </tr>
        <tr>
          <td>A</td>
          <td>:</td>
          <td>Advancement</td>
        </tr>
      </tbody>
    </table>
          {/* <p className='footer-copyright'> Copyright © 2024 <strong>Nexonica</strong>. All rights reserved.</p> */}
        </div>
        <div className='footer-content-center'>
            <h3>Services</h3>
            <ul>
            <li><a href="/mobileapp">Mobile Application</a></li>
            <li><a href="/webdevelopment">Website Development</a></li>
            <li><a href="/softwaredevelopment">Software Development</a></li>
            <li><a href="/researchdevelopment">Research & Development</a></li>
            <li><a href="/training">Training and Work Internship</a></li>
            <li><a href="/digitalmarketing">Digital Marketing</a></li>
            <li><a href="/iot">IOT (Internet Of Things)</a></li>
            <li><a href="/uiux">UI - UX Designing</a></li>
            <li><a href="/ai">Artificial Intelligence</a></li>
        </ul>
        </div>
        <div className='footer-content-right'>
          <h4>Contact Us</h4>
          <ul className="contact-list">
  <li>
    <img src={Location} alt="Address Icon" className="contact-icon add-icon" />
    <span className="contact-text">F5, Ashtvinayak Park C, near Croma, behind HDFC bank, College Rd, Thatte Nagar, Nashik, Maharashtra 422005</span>
  </li>
  <li>
    <img src={Phone} alt="Phone Icon" className="contact-icon" />
    <span className="contact-text">+91 7775022203</span>
  </li>
  <li>
    <img src={Mail} alt="Mail Icon" className="contact-icon mail-icon" />
    <span className="contact-text">systems@nexonica.com</span>
  </li>
</ul>
          <div>
            <h5>Follow On</h5>
            <img src={Icon} alt="Follow Icon" className="icon-img" />
          </div>
        </div>
        <p className='footer-copyright'> Copyright © 2015 <strong>Nexonica</strong>. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
