import React from 'react'

import web from '../../Assets/Website designer.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Webdevelopment = () => {
  return (
    <div className="page-content" >
      {/* <img src={head} alt="" className="serviceshead-image" />
      <h4 className='titletext2'>Services We Offer</h4>
      <p className='para-text2'>
            Building Digital world as a solution for your real-time
            requirements helps you to control the system through  our
            services.
          </p> */}
    <div className='container'>
      <h1 className='page-heading'>Web Development</h1>
      <img src={web} alt="" className="middle-image"  />
      <p className='text'>Everything you need. We fulfill the expectation and build the more websites for various purposes. Website development involves creating and maintaining websites. It encompasses a variety of tasks, including web design, web content creation, client-side/server-side scripting, and network security configuration.Web development is the process of creating and maintaining websites. It encompasses a variety of tasks, including web design, coding, and content management. </p>
       <p className='text2'>Web development is closely related to the job of designing the features and functionality of apps (web design). The term development is usually reserved for the actual construction of these things (that is to say, the programming of sites). 


      </p>
    </div>
    <div>
    <h2 className='chooseus-heading'>
        Why Choose Us</h2>
    </div>
    <div className="card2-container">
        <div className="card2">
          <img src={costImage} alt="Card 1" />
          <h3>Cost Effective</h3>
        </div>
        <div className="card2">
          <img src={easyImage} alt="Card 2" />
          <h3>Easy of use</h3>
        </div>
        <div className="card2">
          <img src={teamImage} alt="Card 3" />
          <h3>Team Work</h3>
        </div>
        <div className="card2">
          <img src={securityImage} alt="Card 4" />
          <h3>Security</h3>
        </div>
    </div>
    </div>
  )
}

export default Webdevelopment
