// src/Component/Card/Card.js
import React from 'react';
import './Card.css'; 

const Card = ({ title, circleImage, description,onClick }) => {
  return (
    <div className="card" onClick={onClick} style={{borderRadius:'30px'}}>
      <div className="card-circle"> 
      <img src={circleImage} alt="" className="card-circle-image" />
      </div>
      <div className="card-content">
        <h2 className="card-title" >{title}</h2>
        <p className="card-description">{description}</p>
        
      </div>
    </div>
  );
};

export default Card;
