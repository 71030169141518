import React from 'react';
import Card from './Card'; 
import './Services.css';
import mobileapp from '../../Assets/mobile-app 1.png';
import development from '../../Assets/development 1.png';
import marketing from '../../Assets/marketing 1.png';
import iot from '../../Assets/iot 1.png';
import layer from '../../Assets/layers 1.png';
import ux from '../../Assets/ux.png';
import traineeship from '../../Assets/traineeship 1.png';
import artificial from '../../Assets/artificial-intelligence.png';
import search from '../../Assets/search (1) 1.png';
import head from '../../Assets/servicehero.png';

const Services = () => {
  return (
    <div className="services">
      <img src={head} alt="" className="serviceshead1-image" />
      <h4 className='titletext'>Services We Offer</h4>
      <p className='para-text'>
        Building Digital world as a solution for your real-time
        requirements helps you to controlled system through our
        services.
      </p>
      <h5 className="services-heading">Our Services</h5>
      <div className="services-grid">
        <a href="/mobileapp" style={{ textDecoration: 'none' }}>
          <Card 
            circleImage={mobileapp}
            title="Mobile Apps"
            description="Your Vision, Our future. Build your dream app and experience with us. Mobile applications should provide real and meaningful value to users."
          />
        </a>
        <a href="/Webdevelopment" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={development}
            title="Website Development"
            description="Everything you need. We fulfill expectations and build websites for various purposes. It involves creating and maintaining websites."
          />
        </a>
        <a href="/Softwaredevelopment" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={layer}
            title="Software Development"
            description="Innovative Software Development, transforming ideas into powerful digital solutions. Software is the set of instructions or program."
          />
        </a>
        <a href="/Researchdevelopment" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={search}
            title="Research & Development"
            description="Fuel innovation with our Research & Development, pioneering solutions for a brighter future. valuable tool for growing and improving business. "
          />
        </a>
        <a href="/Training" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={traineeship}
            title="Training and Work Internship"
            description="Empower with essential technical skills for application design, development, and operations."
          />
        </a>
        <a href="/Digitalmarketing" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={marketing}
            title="Digital Marketing"
            description="Amplify your brand's reach and impact with strategic Digital Marketing. It encompasses a wide range of strategies."
          />
        </a>
        <a href="/IOT" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={iot}
            title="IOT (Internet Of Things)"
            description="IoT devices can automate tasks, improve efficiency, and provide convenience."
          />
        </a>
        <a href="/Uiux" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={ux}
            title="UI/UX Design"
            description="Crafting the visual elements of a product, including layout, color schemes, and interactive elements."
          />
        </a>
        <a href="/AI" style={{ textDecoration: 'none' }}>
          <Card
            circleImage={artificial}
            title="Artificial Intelligence (AI)"
            description="Artificial intelligence makes it possible for machines to learn from experience."
          />
        </a>
      </div>
    </div>
  );
};

export default Services;
