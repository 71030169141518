import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Media.css';
import mediatop from '../../Assets/mediatop (4).png';
import mediahead from '../../Assets/mediahead.png';

// Create contexts to import all images from each category folder
const allCategoryImages = require.context('../../Assets/AllCategories', false, /\.(png|jpg|jpeg|svg|JPG)$/);
const placementDriveImages = require.context('../../Assets/PlacementDrive', false, /\.(png|jpe?g|svg|JPG)$/);
const funActivityImages = require.context('../../Assets/FunActivity', false, /\.(png|jpe?g|svg|JPG)$/);
const birthdayImages = require.context('../../Assets/Birthday', false, /\.(png|jpe?g|svg|JPG)$/);
const functionsImages = require.context('../../Assets/Functions', false, /\.(png|jpe?g|svg|JPG)$/);

const Media = () => {
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(null);

  const getImagesFromCategory = (imageContext, category) => {
    return imageContext.keys().map((image) => ({
      src: imageContext(image),
      category: category,
    }));
  };

  const allImages = [
    ...getImagesFromCategory(allCategoryImages, 'All Categories'),
    ...getImagesFromCategory(placementDriveImages, 'Placement Drive'),
    ...getImagesFromCategory(funActivityImages, 'Fun Activity'),
    ...getImagesFromCategory(birthdayImages, 'Birthday'),
    ...getImagesFromCategory(functionsImages, 'Functions'),
  ];

  const filteredImages = selectedCategory === 'All Categories'
    ? allImages
    : allImages.filter(image => image.category === selectedCategory);

  const openFullScreen = (index) => {
    setFullScreenImageIndex(index);
  };

  const closeFullScreen = () => {
    setFullScreenImageIndex(null);
  };

  const nextImage = () => {
    setFullScreenImageIndex((prevIndex) => (prevIndex + 1) % filteredImages.length);
  };

  const prevImage = () => {
    setFullScreenImageIndex((prevIndex) => (prevIndex - 1 + filteredImages.length) % filteredImages.length);
  };

  return (
    <div className="media-container">
      <div className='media-top'>
        <img src={mediatop} alt="media hero" className="mediahero-image" />
        <img src={mediahead} alt="media hero" className="media-head-image" />
        {/* <h1 className='media-head'>"Immerse yourself in a visual journey through our curated gallery."</h1> */}
      </div>
      <div className="media-nav">
        <ul>
          {['All Categories', 'Placement Drive', 'Training', 'Life @ Nexonica'].map((category) => (
            <li key={category} className={selectedCategory === category ? 'active' : ''}>
              <NavLink
                to="/media"
                className={selectedCategory === category ? 'active-link' : 'nav-link'}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="image-gallery">
        {filteredImages.map((image, index) => (
          <img 
            src={image.src} 
            alt={`media ${index + 1}`} 
            key={index} 
            className="gallery-image" 
            onClick={() => openFullScreen(index)} // Open full screen on click
          />
        ))}
      </div>

      {fullScreenImageIndex !== null && (
        <div className="fullscreen-overlay" onClick={closeFullScreen}>
          <button className="nav-button left" onClick={(e) => { e.stopPropagation(); prevImage(); }}>←</button>
          <img 
            src={filteredImages[fullScreenImageIndex].src} 
            alt="Full screen" 
            className="fullscreen-image" 
          />
          <button className="nav-button right" onClick={(e) => { e.stopPropagation(); nextImage(); }}>→</button>
        </div>
      )}
    </div>
  );
};

export default Media;
