// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Component/Home/Home';
import AboutUs from './Component/About/AboutUs';
import Services from './Component/Services/Services';
import Media from './Component/Media/Media';
import Navbar from './Module/Navbar/Navbar';
import Footer from './Module/Footer/Footer';
import MobileApp from './Component/Services/mobileapp';
import Webdevelopment from './Component/Services/Webdevelopment';
import Softwaredevelopment from './Component/Services/Softwaredevelopment';
import Researchdevelopment from './Component/Services/Researchdevelopment';
import Training from './Component/Services/Training';
import Digitalmarketing from './Component/Services/DigitalMarketing';
import Iot from './Component/Services/IOT';
import Uiux from './Component/Services/UIux';
import Ai from './Component/Services/AI';
import 'bootstrap/dist/css/bootstrap.min.css';
import Learning from './Component/Learning/Learning';
import ContactUs from './Component/Contact/ContactUs';
import Career from './Component/Career/Career';




const App = () => (
  <div>
    <Navbar />
  <div>

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/mobileapp" element={<MobileApp />} />
      <Route path="/webdevelopment" element={<Webdevelopment/>}/> 
      <Route path="/softwaredevelopment" element={<Softwaredevelopment/>}/>
      <Route path="/researchdevelopment" element={<Researchdevelopment/>}/> 
      <Route path="/training" element={<Training/>}/> 
      <Route path="/digitalmarketing" element={<Digitalmarketing/>}/>  
      <Route path="/iot" element={<Iot/>}/>  
      <Route path="/uiux" element={<Uiux/>}/>  
      <Route path="/ai" element={<Ai/>}/>  
      <Route path="/media" element={<Media />} />
      <Route path="/learning" element={<Learning />} />
      <Route path="/career" element={<Career />} />
      <Route path="/contactus" element={<ContactUs />} />
    </Routes>
    
  </div>
  <Footer />
  </div>
);

export default App;
