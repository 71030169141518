import React from 'react'

import training from '../../Assets/workshop.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Training= () => {
  return (
    <div className="page-content">
      {/* <img src={head} alt="" className="serviceshead-image" />
      <h4 className='titletext2'>Services We Offer</h4>
      <p className='para-text2'>
            Building Digital world as a solution for your real-time
            requirements helps you to control the system through  our
            services.
          </p> */}
    <div className='container'>
      <h1 className='page-heading'>Training & Work Intership</h1>
      <img src={training} alt="" className="middle-image" />
      <p className='text'>Empower with essential technical skills for application design, development, and operations.Training is typically designed to teach new skills or knowledge to employees, while internships are designed to provide hands-on experience to students or recent graduates. Training is a process of learning new skills or knowledge, usually in a formal setting such as a classroom or training center.</p>
       <p className='text2'><br/>An internship, on the other hand, is a period of on-the-job training where a person works in a particular field or industry to gain practical experience and develop skills. Internships are often completed as part of a college or university program and may be paid or unpaid.
      </p>
    </div>
    <div>
    <h2 className='chooseus-heading'>
        Why Choose Us</h2>
    </div>
    <div className="card2-container">
        <div className="card2">
          <img src={costImage} alt="Card 1" />
          <h3>Cost Effective</h3>
        </div>
        <div className="card2">
          <img src={easyImage} alt="Card 2" />
          <h3>Easy of use</h3>
        </div>
        <div className="card2">
          <img src={teamImage} alt="Card 3" />
          <h3>Team Work</h3>
        </div>
        <div className="card2">
          <img src={securityImage} alt="Card 4" />
          <h3>Security</h3>
        </div>
    </div>
    </div>
  )
}

export default Training
