import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import './Home.css'; 
import wave from '../../Assets/Wave.png';
import js from '../../Assets/JS.gif'; 
import square from '../../Assets/Square.png';
import about from '../../Assets/About.png';
import more from '../../Assets/more about.png';
import mobileapp from '../../Assets/mobile-app 1.png';
import devlopment from '../../Assets/development 1.png';
import marketing from '../../Assets/marketing 1.png';
import layer from '../../Assets/layers 1.png';
import traineeship from '../../Assets/traineeship 1.png';
import search from '../../Assets/search (1) 1.png';
import viewmore from '../../Assets/viewmore.png';
import app from '../../Assets/Appcourse.png';
import angular from '../../Assets/Angularcourse.png';
import digitalcourse from '../../Assets/DMcourse.png';
import react from '../../Assets/reactcourse.png';
import node from '../../Assets/nodecouurse.png';
import viewmorecourse from '../../Assets/viewmore.png';
import { Col, Row } from 'reactstrap';
import blurwave from '../../Assets/blurwave.png';
import Card from '../Services/Card';
import Nlogo from '../../Assets/Logo.png';
import icon from '../../Assets/icon.png';

const Home = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleMoreAboutClick = () => {
    navigate('/aboutus'); // Navigate to the About page
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
    }, 0);
  };

  const handleViewmoreClick = () => {
    navigate('/services'); // Navigate to the Services page
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0); 
  };

  const handleViewMoreCourseClick = () => {
    navigate('/learning'); // Navigate to the Learning page
    setTimeout(() => window.scrollTo(0, 0), 0); 
  };

  const handleImageClick = (path) => {
    navigate(path); // Navigate to the specified path
    setTimeout(() => window.scrollTo(0, 0), 0); 
  };

  const currentDay = new Date().toLocaleString('en-US', { weekday: 'long' }); // Get the full name of the current day

  const officeHours = [
    { day: 'Monday', time: '10.00am - 6.00pm' },
    { day: 'Tuesday', time: '10.00am - 6.00pm' },
    { day: 'Wednesday', time: '10.00am - 6.00pm' },
    { day: 'Thursday', time: '10.00am - 6.00pm' },
    { day: 'Friday', time: '10.00am - 6.00pm' },
    { day: 'Saturday', time: '10.00am - 6.00pm' },
    { day: 'Sunday', time: 'Closed' },
  ];

  return (
    <div className='home-container'>
      <div className="center-container">
        <Row className="main-row">
          <Col>
            <h1 className="center-text">
              Transform your growth journey <br /> with our despoke IT solutions.
            </h1>
            <div className='image-content'>
              <img 
                src={js} 
                alt="JS" 
                className="JS-gif" 
                
              /> 
            </div>
          </Col>
          
          <Col className="image-container">
            <img 
              src={square} 
              alt="Square" 
              className="Square-img" 
              
            />
          </Col>
        </Row>

        <Row className="second-row">
          <img 
            src={wave} 
            alt="Wave" 
            className="wave2-img" 
            style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
          />
        </Row>
        <Row>
          <div className="about-row">
            <Col>
              <div className="text-content">
                <p className="about-text" >
                  Nexonica is an Indian IT company that provides information technology, 
                  consulting and business process services.
                  We are situated in the world's best place called Nashik. We are an IT solution provider to all of them who want to automate their existing work with the latest technologies,application development and integration, and application management services.
                  The company has been promoted by most of the veteran professionals. We endow with total IT solution under one crown. ....
                </p>
                <img 
                  src={more} 
                  alt="More About" 
                  className="more-image" 
                  onClick={handleMoreAboutClick} // Handle click to navigate
                  style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
                />
              </div>
            </Col>
            <img 
              src={about} 
              alt="About" 
              className="about-image" 
              onClick={() => handleImageClick('/about')} // Example path for About image
            />
          </div>
        </Row>

        <div className="third-row">
          <div className="our-services">
            <h2 className='services-head'>Our Services</h2>
        
        <div className="services1-grid">
        <Card 
         circleImage={mobileapp}
          title= "Mobile Apps"
          description="Your Vision, Our future. Build your dream app and experience with us.mobile application should be to provide real and meaningful value to users."
         
        />
        <Card
          circleImage={devlopment}
          title="Website Development"
          description="Everything you need. We fulfill the expectation and build more websites for various purposes.It involves creating and maintaining websites."
          
        />
        <Card
          circleImage={layer}
          title="Software Development"
          description="Innovative Software Development, transforming ideas into powerful digital solutions.Software is the set of instructions or program."
         
        />
        <Card
          circleImage={search}
          title="Research & Development"
          description="Fuel innovation with our Research & Development, pioneering solutions for a brighter future. valuable tool for growing and improving your business. "
          
        />
        <Card
          circleImage={traineeship}
          title="Training and Work Intership"
          description="Empower with essential technical skills for application design, development, and operations. Training is a process of learning new skills"
          
        />
        <Card
          circleImage={marketing}
          title="Digital Marketing"
          description={<p style={{ marginTop: '30px' }}>"Amplify your brand's reach and impact with strategic Digital Marketing. It encompasses a wide range of strategies."</p>}
         
        />
      </div>
            <img 
              src={viewmore} 
              alt="View More" 
              className="Viewmore-img" 
              onClick={handleViewmoreClick} // Handle click to navigate
            />
          </div>
          <div>
            <h3 className="course-head" >Our Courses</h3>
            <div className='our-course'>
              <img 
                src={app} 
                alt="Course About" 
                className="course-image" 
                onClick={() => handleImageClick('/app')} // Example path for App course image
                style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
              />
              <img 
                src={angular} 
                alt="Angular Course" 
                className="course-image" 
                onClick={() => handleImageClick('/angular')} // Example path for Angular course image
                style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
              />
              <img 
                src={digitalcourse} 
                alt="Digital Course" 
                className="course-image" 
                onClick={() => handleImageClick('/digital')} // Example path for Digital course image
                style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
              />
              <img 
                src={react} 
                alt="React Course" 
                className="course-image" 
                
                style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
              />
              <img 
                src={node} 
                alt="Node Course" 
                className="course-image" 
                onClick={() => handleImageClick('/node')} // Example path for Node course image
                style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
              />
            </div>
            <img 
              src={viewmorecourse} 
              alt="View More Courses" 
              className="viewmore-img" 
              onClick={handleViewMoreCourseClick}  
            />
          </div>
          <img 
            src={blurwave} 
            alt="Blur Wave" 
            className="blurwave-img" 
          />
        </div>
        <div className='contact'>
          <h3 className="contact-head">Contact Us</h3>
          <img 
            src={Nlogo} 
            alt="Nexonica Logo" 
            className="Nlogo-img" 
          />
          <div className="contact-info">
            <h4>Address</h4>
            <p className='address'>F5, Ashtvinayak Park C, near Croma,<br /> College Rd, Thatte Nagar, Nashik,<br /> Maharashtra 422005</p>
            
            <h4>Call Us</h4>
            <p className='callus'>+91 7775022203</p>
            
            <h4>Email</h4>
            <p className='email'>systems@nexonica.com</p>
            <img 
              src={icon} 
              alt="Follow Icon" 
              className="icon1-img" 
            />
          </div>
          <div className='business-hour'>
            <h4 className='hour-head'>Business Hours</h4>
            <table>
              <tbody>
                {officeHours.map(({ day, time }) => (
                  <tr key={day}>
                    <td>
                      <span className={day === 'Sunday' ? 'sunday':(currentDay === day ? 'hour-day' : '')}>{day}</span>
                    </td>
                    <td className="colon">:</td>
                    <td>
                      <span className={day === 'Sunday' ? 'sunday' : (currentDay === day ? 'hour-time' : '')}>
                        {time}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      
          <div className='map-container'>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3748.978424325454!2d73.76184707420977!3d20.009420222009282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeba75b234859%3A0x5daa8be31168c7f3!2sEmerging%20Technologies%20Software%20%26%20Automation%20solution!5e0!3m2!1sen!2sin!4v1725352560470!5m2!1sen!2sin" 
              width="600" 
              height="450" 
              style={{ border: 0 }} 
              allowFullScreen 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          
        </div>
      </div> 
    </div>
  );
};

export default Home;
