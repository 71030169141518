import React from 'react';
import './career.css';
import Applycard from './Applycard'; 
import careernwave from '../../Assets/Wave.png';
import careerside from '../../Assets/Square.png';
import model from '../../Assets/model.png';
import GoogleForm from './careerform'; // Ensure the import path is correct

const Career = () => {
  return (
    <div className='Application-container'>
      <img src={careernwave} alt="learning" className="careernwave-img" />
      <img src={model} alt="learning" className="model-img" />
      <img src={careerside} alt="learning" className="careerside-img" />
      <h3 className='career-top'>Career With Nexonica</h3>
      <p className='career-text'>"Excited to contribute and grow with <br/> Nexonica,where innovation meets<br/>excellence."</p>
      <h1 className='career-opportunities'>
        Career Opportunities
      </h1>

      <h2 className='submit-application'>
        Submit Your Application
      </h2>
      <GoogleForm/>

      {/* The rest of your Career component code... */}
      {/* <div className='applycard-container'>
        <Applycard
          logo={cardlogo}
          title="BDE"
          image1={image1}
          description1="2"
          image2={image2}
          description2="BE"
        />
        <Applycard
          logo={cardlogo}
          title="HR"
          image1={image1}
          description1="2"
          image2={image2}
          description2="MBA"
        />
        <Applycard
          logo={cardlogo}
          title="Python"
          image1={image1}
          description1="2"
          image2={image2}
          description2="BE"
        />
        <Applycard
          logo={cardlogo}
          title="Content Writer"
          image1={image1}
          description1="1"
          image2={image2}
          description2="BE"
        />
      </div> */}
    </div>
  );
}

export default Career;
