import React from 'react';
import './About.css';
import aboutsquare1  from '../../Assets/Rectangle.png';
import aboutsquare2 from '../../Assets/Rectangle.png';
import missionlogo from '../../Assets/missionlogo.png';
import visionimg from '../../Assets/visionlogo.png';
import abouthero from '../../Assets/abouthero.png';
import image1 from '../../Assets/image (1).png';
import image2 from '../../Assets/image (2).png';
import image3 from '../../Assets/image (3).png';
import image4 from '../../Assets/image (4).png';
import image5 from '../../Assets/image (5).png';
import image6 from '../../Assets/image (6).png';
import image7 from '../../Assets/image (7).png';

const About = () => {

  return (
    <div className="main-content">
      <div className="about-container">
        <img src={abouthero} alt="About hero" className="abouthero-image" />
        <h3 className="header">About Us</h3>
        <p
         className='about-para'
        >
          "Transforming ideas into results with creativity and precision."
        </p>
        <p className='about-details'>
          Nexonica is an Indian IT company that provides information technology, consulting, and business process services. We are situated at the world’s best place called Nashik. We are an IT solution provider to all of them who want to automate their existing work with the latest technologies, application development and integration, and application management services.<br />
          The company has been promoted by most of the veteran professionals. We endow with total IT solution under one crown. Emerging Technologies is one of the Pioneer IT companies in Nashik, who work on the latest technologies like IoT, Raspberry with everything Smarting to Automation. We also work on Android, Angular, PHP, Java, Firebase, Arduino. Our expertise lies in the ability to create innovative systems application software to meet business-specific needs. We provide extraordinary solutions to automate your daily business needs with ease. The company is focused on new opportunities in the IT and computing industry, automating its existing work at a nascent stage in Nashik and all over Maharashtra. Very soon we will be providing the solution throughout India and all over the world. The company deals in e-commerce, mobile applications, cloud computing, digital transformation, data analytics, enterprise application integration, and enterprise resource planning, with more than 100 active clients across the country. We are also a part of training for industrial and educational institutes, serving seminars across the institutes to get aware of the latest technologies and trends. We also provide internships for various students as well as short-term training in the latest trends in development and IoT-related fields.
        </p>
        <h4 className="core-head">Our Core Values</h4>
        <div className="horizontal-scroll-container">
          <div className="image-item">
            <img src={image1} alt="Slide 1" />
            <p className="image-caption">Innovation</p>
          </div>
          <div className="image-item">
            <img src={image2} alt="Slide 2" />
            <p className="image-caption">Goals</p>
          </div>
          <div className="image-item">
            <img src={image3} alt="Slide 3" />
            <p className="image-caption">Teamwork</p>
          </div>
          <div className="image-item">
            <img src={image4} alt="Slide 4" />
            <p className="image-caption">Commitment</p>
          </div>
          <div className="image-item">
            <img src={image5} alt="Slide 5" />
            <p className="image-caption">Integrity</p>
          </div>
          <div className="image-item">
            <img src={image6} alt="Slide 6" />
            <p className="image-caption">Customers</p>
          </div>
          <div className="image-item">
            <img src={image7} alt="Slide 7" />
            <p className="image-caption">Responsibility</p>
          </div>
        </div>

        
        <h5 className='our-text'>Our Vision</h5>
        <div className='vision-container'>
        <img src={aboutsquare1} alt="About hero" className="aboutsquare-image" />
          <p className='vision-text'>Our vision at Nexonica is to be the catalyst for transformative change in the business landscape.</p>
          <img src={visionimg} alt="Vision logo" className="visionimg-image" />
          <p className='vision-text-details'>We envision a future where every business,regardless of its size or industry, harnesses the power of emerging technologies to achieve unparalleled success, efficiency, and sustainability. and excellence in every sector we touch.</p>  
        </div> 

        <h6 className='mission-head' >Our Mission</h6>
        <div className='mission-container'>
        <img src={aboutsquare2} alt="About hero" className="aboutsquare2-image" />
         <p className='mission-text'>We are committed to innovation, collaboration, and quality, providing  adaptable solutions designed to evolve with our clients’ changing needs.</p>
          <img src={missionlogo} alt="mission logo" className="missionlogo-image" />
          <p className='mission-text-details'> Our success is measured by the positive impact our solutions have on the growth and sustainability of the businesses we  partner with.Empowering businesses through cutting-edge software and automation solutions. </p> 
        </div>
        
      </div>
    </div>
  );
};

export default About;
