import React from 'react'
import research from '../../Assets/Research development (1).gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Researchdevelopment = () => {
  return (
    <div className="page-content">
      {/* <img src={head} alt="" className="serviceshead-image" />
      <h4 className='titletext2'>Services We Offer</h4>
      <p className='para-text2'>
            Building Digital world as a solution for your real-time<br/>
            requirements helps you to control the system through <br/> our
            services.
          </p> */}
    <div className='container'>
      <h1 className='page-heading research'>Research & Development</h1>
      <img src={research} alt="" className="middle-image" />
      <p className='text'>Fuel innovation with our Research & Development, pioneering solutions for a brighter future. Research and Development (R&D) is a critical aspect of innovation and growth for organizations, industries, and economies. It involves the systematic work conducted to increase knowledge, and the use of this knowledge to create new applications, products, processes, or services.</p>
       <p className='text2'><br/>Research and development (R&D) is a valuable tool for growing and improving your business. R&D involves researching your market and your customer needs and developing new and improved products and services to fit these needs.
      </p>
    </div>
    <div>
    <h2 className='chooseus-heading'>
        Why Choose Us</h2>
    </div>
    <div className="card2-container">
        <div className="card2">
          <img src={costImage} alt="Card 1" />
          <h3>Cost Effective</h3>
        </div>
        <div className="card2">
          <img src={easyImage} alt="Card 2" />
          <h3>Easy of use</h3>
        </div>
        <div className="card2">
          <img src={teamImage} alt="Card 3" />
          <h3>Team Work</h3>
        </div>
        <div className="card2">
          <img src={securityImage} alt="Card 4" />
          <h3>Security</h3>
        </div>
    </div>
    </div>
  )
}

export default Researchdevelopment
