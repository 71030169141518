import React from 'react'

import software from '../../Assets/software development.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const softwaredevelopment = () => {
  return (
    <div className="page-content">
      {/* <img src={head} alt="" className="serviceshead-image" />
      <h4 className='titletext2'>Services We Offer</h4>
      <p className='para-text2'>
            Building Digital world as a solution for your real-time<br/>
            requirements helps you to control the system through <br/> our
            services.
          </p> */}
    <div className='container'>
      <h1 className='page-heading software'>Software Development</h1>
      <img src={software} alt="" className="middle-image"  />
      <p className='text'>Innovative Software Development, transforming ideas into powerful digital solutions. Software development refers to a set of computer science activities that are dedicated to the process of creating, designing, deploying, and supporting software. It involves a variety of tasks and stages that collectively bring a software product from concept to reality.Software development is the process of designing, creating, testing, and maintaining software applications.</p>
       <p className='text2'>Software itself is the set of instructions or programs that tell a computer what to do. It is independent of hardware and makes computers programmable.


      </p>
    </div>
    <div>
    <h2 className='chooseus-heading'>
        Why Choose Us</h2>
    </div>
    <div className="card2-container">
        <div className="card2">
          <img src={costImage} alt="Card 1" />
          <h3>Cost Effective</h3>
        </div>
        <div className="card2">
          <img src={easyImage} alt="Card 2" />
          <h3>Easy of use</h3>
        </div>
        <div className="card2">
          <img src={teamImage} alt="Card 3" />
          <h3>Team Work</h3>
        </div>
        <div className="card2">
          <img src={securityImage} alt="Card 4" />
          <h3>Security</h3>
        </div>
    </div>
    </div>
  )
}

export default softwaredevelopment
