import React,{ useState }  from 'react';
import './contact.css';
import contacthero from '../../Assets/contacthead.png';
import gps from '../../Assets/gps 1.png';
import phn from '../../Assets/telephone-call 2.png';
import mail from '../../Assets/email (1) 2.png';

const ContactUs = () => {
  return (
    <div>
    <div className='contact-container'>
      <img src={contacthero} alt="Contact hero" className="contacthero-image" />
      <h1 className='contact-top'>
        Get in touch with us now and our representative will help you soon.</h1>
        </div>
        <div className='form-container'>
      <form name='form2'>
      <input placeholder="Enter First Name"/>
      <div className='form-row'>
        <div className='form-group'>
              <input placeholder="Mobile No" />
            </div>
            <div className='form-group'>
              <input placeholder="Enter email" />
            </div>
            </div>
      <textarea placeholder="Message"/>
      <button>Submit</button>
      </form>

      <ul className="contactus">
  <li>
  <img src={mail} alt="Mail Icon" className="contactus-icon mail-icon" />
  <p className="contact1-text">systems@nexonica.com</p>
  </li>
  <li>
    <img src={phn} alt="Phone Icon" className="contactus-icon" />
    <p className="contact1-text">+91 7775022203</p>
  </li>
  <li>
    <img src={gps} alt="Address Icon" className="contactus-icon address-icon " />
    <p className="contact1-text">F5, Ashtvinayak Park C, near Croma,behind HDFC bank College Rd, Thatte Nagar, Nashik, Maharashtra 422005</p>
  </li>
</ul>
</div>
    
    <div className='location-container'>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3748.978424325454!2d73.76184707420977!3d20.009420222009282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeba75b234859%3A0x5daa8be31168c7f3!2sEmerging%20Technologies%20Software%20%26%20Automation%20solution!5e0!3m2!1sen!2sin!4v1725352560470!5m2!1sen!2sin" 
              title='map'
              width="1390" 
              height="450" 
              style={{ border: 0 }} 
              allowFullScreen 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
    </div> 
   
    
  );
}

export default ContactUs;