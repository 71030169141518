import React from 'react';
import './learncard.css'; 

const learncard = ({ image, title, description }) => {
  return (
    <div className="learncard">
      <img src={image} alt={title} className="learncard-image" />
      <div className="learncard-content">
        <h3 className="learncard-title">{title}</h3>
        <p className="learncard-description">{description}</p>
      </div>
    </div>
  );
};

export default learncard;
