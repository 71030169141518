import React from 'react'
import ai from '../../Assets/artificial intelligence.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Ai = () => {
  return (
    <div className="page-content">
      {/* <img src={head} alt="" className="serviceshead-image" />
      <h4 className='titletext2'>Services We Offer</h4>
      <p className='para-text2'>
            Building Digital world as a solution for your real-time
            requirements helps you to control the system through our
            services.
          </p> */}
    <div className='container'>
      <h1 className='page-heading' >Artificial Intelligence(AI)</h1>
      <img src={ai} alt="" className="middle-image" />
      <p className='text'>These tasks include learning, reasoning, problem-solving, perception, understanding natural language, and even creativity. AI encompasses a broad range of techniques and technologies, from simple rule-based systems to complex deep learning models.Artificial intelligence makes it possible for machines to learn from experience, adjust to new inputs and perform human-like tasks.</p>
       <p className='text2'> <br/>Artificial Intelligence (AI) is a branch of computer science that focuses on creating systems capable of performing tasks that typically require human intelligence. AI systems that are designed and trained to perform a specific task or a narrow set of tasks.

      </p>
    </div>
    <div>
    <h2 className='chooseus-heading'>
        Why Choose Us</h2>
    </div>
    <div className="card2-container">
        <div className="card2">
          <img src={costImage} alt="Card 1" />
          <h3>Cost Effective</h3>
        </div>
        <div className="card2">
          <img src={easyImage} alt="Card 2" />
          <h3>Easy of use</h3>
        </div>
        <div className="card2">
          <img src={teamImage} alt="Card 3" />
          <h3>Team Work</h3>
        </div>
        <div className="card2">
          <img src={securityImage} alt="Card 4" />
          <h3>Security</h3>
        </div>
    </div>
    </div>
  )
}

export default Ai
