import React from 'react'

import uiux from '../../Assets/UI-UX.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Uiux = () => {
  return (
    <div className="page-content">
      {/* <img src={head} alt="" className="serviceshead-image"  />
      <h4 className='titletext2'>Services We Offer</h4>
      <p className='para-text2'>
            Building Digital world as a solution for your real-time
            requirements helps you to control the system through our
            services.
          </p> */}
    <div className='container'>
      <h1 className='page-heading'>UI/UX Design</h1>
      <img src={uiux} alt="" className="middle-image" />
      <p className='text'>UI and UX designers often work closely together to create products that are both beautiful and functional. A great UI design enhances the user experience, and a well-thought-out UX design supports the user interface.Crafting the visual elements of a product, including layout, color schemes, and interactive elements.UX design is about the overall experience a user has when interacting with a product. </p>
       <p className='text2'><br/> UI/UX Design refers to the process of designing user interfaces (UI) and user experiences (UX) to create products that are not only functional but also aesthetically pleasing and easy to use. These two concepts, while often discussed together, focus on different aspects of product design.</p>
    </div>
    <div>
    <h2 className='chooseus-heading'>
        Why Choose Us</h2>
    </div>
    <div className="card2-container">
        <div className="card2">
          <img src={costImage} alt="Card 1" />
          <h3>Cost Effective</h3>
        </div>
        <div className="card2">
          <img src={easyImage} alt="Card 2" />
          <h3>Easy of use</h3>
        </div>
        <div className="card2">
          <img src={teamImage} alt="Card 3" />
          <h3>Team Work</h3>
        </div>
        <div className="card2">
          <img src={securityImage} alt="Card 4" />
          <h3>Security</h3>
        </div>
    </div>
    </div>
  )
}

export default Uiux
