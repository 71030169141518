import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Ensure this path is correct for your setup
import Logo from '../../Assets/Logo.png'; // Ensure this path is correct for your setup

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen); // Toggle mobile menu

  // Close dropdown and navigate
  const handleDropdownClick = (path) => {
    setIsDropdownOpen(false); // Close the dropdown
    setIsMenuOpen(false); // Close the mobile menu
    window.location.href = path; // Navigate to the selected path
  };

  // Handle navigation link click
  const handleNavLinkClick = () => {
    setIsMenuOpen(false); // Close the mobile menu when a link is clicked
  };

  return (
    <div className="navbar" style={{ position: 'fixed', width: '100%' }}>
      <div className="logo-row">
        <NavLink to="/">
          <img src={Logo} alt="Logo" className="logo-img" />
        </NavLink>
      </div>

      <button 
        className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} 
        onClick={toggleMenu}
      >

          <span className="menu-icon">&#9776;</span> 
      
      </button>

      {/* Navigation Links */}
      <div className="nav-row">
        <ul className={`nav-links ${isMenuOpen ? 'show' : ''}`}>
          <li>
            <NavLink 
              to="/" 
              className={({ isActive }) => (isActive ? 'active-link' : 'nav-link')}
              onClick={handleNavLinkClick} // Close menu on link click
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/aboutus" 
              className={({ isActive }) => (isActive ? 'active-link' : 'nav-link')}
              onClick={handleNavLinkClick} // Close menu on link click
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/services" 
              className={({ isActive }) => (isActive ? 'active-link' : 'nav-link')}
              onClick={handleNavLinkClick} // Close menu on link click
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/learning" 
              className={({ isActive }) => (isActive ? 'active-link' : 'nav-link')}
              onClick={handleNavLinkClick} // Close menu on link click
            >
              Learning
            </NavLink>
          </li>
          <li className="dropdown">
            <div 
              onClick={toggleDropdown} 
              className="dropdown-button"
            >
              <NavLink
                to="/media"
                className={({ isActive }) => (isActive ? 'active-link' : 'nav-link')}
                onClick={handleNavLinkClick} // Close menu on link click
              >
                Media
              </NavLink>
            </div>
          </li>
          <li>
            <NavLink 
              to="/career" 
              className={({ isActive }) => (isActive ? 'active-link' : 'nav-link')}
              onClick={handleNavLinkClick} // Close menu on link click
            >
              Career
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/contactus" 
              className={({ isActive }) => (isActive ? 'active-link' : 'contact-link')}
              style={({ isActive }) => ({
                color:'white',
                backgroundColor: isActive ? '#f7941d' : '#0a466d',
                borderRadius: '6px',
                padding: '4px'
              })}
              onClick={handleNavLinkClick} // Close menu on link click
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
